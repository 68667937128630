import axios from "axios";
import store from "./store";
import i18n from "./i18n";

const instance = new axios.create({
  baseURL: process.env.VUE_APP_BACKEND_API,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "Accept-Language": i18n.global.locale,
  },
});

instance.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = i18n.global.locale;

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (e) => {
    const { data } = e.response;
    let errorMessage = data?.message;
    store.commit("alert/SHOW_ALERT", errorMessage);
    return Promise.reject(e);
  }
);

export default instance;
