const state = {
  reservation: null,
  hotel: null,
  upSale: null,
  upsaleData: null,
  guestDetailsForm: [],
  specialRequest: null,
  companyDetails: null,
  checkinTime: null,
};
const getters = {
  reservation(state) {
    return state.reservation;
  },
  hotel(state) {
    return state.hotel;
  },
  upSale(state) {
    return state.upSale;
  },
  upsaleData(state) {
    return state.upsaleData;
  },
  guestDetailsForm(state) {
    return state.guestDetailsForm;
  },
  specialRequest(state) {
    return state.specialRequest;
  },
  companyDetails(state) {
    return state.companyDetails;
  },
  checkinTime(state) {
    return state.checkinTime;
  },
};
const mutations = {
  SET_RESERVATION(state, payload) {
    for (const detailsBlock in payload["guestInformation"]["guestDetails"]) {
      if (!payload["guestInformation"]["guestDetails"][detailsBlock]) {
        delete payload["guestInformation"]["guestDetails"][detailsBlock];
        continue;
      }
      for (const detailsStruct in payload["guestInformation"]["guestDetails"][
        detailsBlock
      ]["structure"]) {
        if (
          !payload["guestInformation"]["guestDetails"][detailsBlock][
            "structure"
          ][detailsStruct]
        ) {
          delete payload["guestInformation"]["guestDetails"][detailsBlock][
            "structure"
          ][detailsStruct];
        }
      }
    }
    if (payload["guestInformationChild"]) {
      for (const detailsBlock in payload["guestInformationChild"][
        "guestDetails"
      ]) {
        if (!payload["guestInformationChild"]["guestDetails"][detailsBlock]) {
          delete payload["guestInformationChild"]["guestDetails"][detailsBlock];
          continue;
        }
        for (const detailsStruct in payload["guestInformationChild"][
          "guestDetails"
        ][detailsBlock]["structure"]) {
          if (
            !payload["guestInformationChild"]["guestDetails"][detailsBlock][
              "structure"
            ][detailsStruct]
          ) {
            delete payload["guestInformationChild"]["guestDetails"][
              detailsBlock
            ]["structure"][detailsStruct];
          }
        }
      }
    }
    state.reservation = payload;
  },
  UPDATE_RESERVATION(state, payload) {
    const { index, ...data } = payload;
    state.reservation["reservation"]["profiles"][index] = {
      ...state.reservation["reservation"]["profiles"][index],
      ...data,
    };
  },
  SET_HOTEL(state, payload) {
    state.hotel = payload;
  },
  DELETE_FILE(state, { userIndex, fileUrl }) {
    const files =
      state.reservation["reservation"]["profiles"][userIndex][
        "uploadedDocuments"
      ];
    const fIndex = files.indexOf(fileUrl);
    files.splice(fIndex, 1);
  },
  SET_UPSALE(state, payload) {
    state.upSale = payload;
  },
  SET_GUEST_DETAILS_FORM(state, userIndex, payload) {
    state.guestDetailsForm[userIndex] = payload;
  },
  SET_SPECIAL_REQUEST(state, payload) {
    state.specialRequest = payload;
  },
  SET_COMPANY_DETAILS(state, payload) {
    state.companyDetails = payload;
  },
  SET_CHECKIN_TIME(state, payload) {
    state.checkinTime = payload;
  },
  ADD_FILE(state, { userIndex, fileUrl }) {
    const files =
      state.reservation["reservation"]["profiles"][userIndex][
        "uploadedDocuments"
      ];
    files.push(fileUrl);
  },
  UPDATE_SIGNATURE(state, payload) {
    state.reservation["reservation"]["signature"] = payload;
  },
  SET_UPSALE_DATA(state, payload) {
    state.upsaleData = payload;
  },
};
export default { state, getters, mutations };
