export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto pole je povinné"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimálny počet znakov – ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximálny počet znakov – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte platný e-mail"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte platné mobilné číslo"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte platný dátum"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začať registráciu"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súhlas"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš podpis"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím, nechajte váš podpis"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prečítal(a) som, pochopil(a) som a súhlasím s Podmienkami služby"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áno, súhlasím"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie, nesúhlasím"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša rezervácia"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príchod"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odchod"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noci"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostia"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením tlačidla súhlasíte <br /> s "])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmienkami služby"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť podrobnosti"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie o hostiach"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrané dokumenty"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti o hostovi"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skeny dokladov"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrajte fotky pasu alebo naskenujte váš pas na overenie identity. Tieto údaje neukladáme."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite pre zadaní údajov vedúceho hosta"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite pre zadaní údajov sprievodného hosta"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte krajinu"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripravte si svoj občiansky preukaz a usmejte sa pre automatické overenie dokladu a proces overovania. Nebude to trvať dlhšie ako 1 minútu."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrať cestovný doklad"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naskenovať cestovný doklad"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začať proces overovania"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Špeciálne požiadavky"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požiadavka"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba"])},
      "arrivalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas príchodu"])},
      "earlierCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skorší príchod musí byť potvrdený ubytovacím zariadením"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplaťte online teraz"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť pri príchode"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím, vyberte spôsob platby za váš pobyt"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka: Personál hotela potvrdí možnosť ďalších služieb na požiadanie neskôr. Za ne neplatíte v tomto čase."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš pobyt"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubytovanie"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnkové služby"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatené"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spolu"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " noc"]), _normalize([_interpolate(_named("n")), " noci"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vylepšite si svoj pobyt"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridajte doplnky na zlepšenie vášho pobytu ešte pred príchodom a budete milo prekvapení\n"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súkromný transfer"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím, poskytnite svoje letisko, dátum príchodu a číslo letu, aby sme pre vás mohli zariadiť prepravu"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letisko"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet hostí"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum príchodu"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo letu"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďakujeme!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milý/á"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš predregistrovaný pobyt bol úspešne dokončený. Pred príchodom od nás dostanete e-mail s konečným potvrdením registrácie a pokynmi. Ďakujeme a tešíme sa na vás v Hotele Demo."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakty hotela"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kliknite pre skopírovanie!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súradnice sú skopírované!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba zlyhala"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše platobné údaje sú nesprávne. Prosím, skúste to znova alebo sa vráťte a vyberte platbu pri príchode."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skúsiť znova"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím, vyplňte vaše údaje pre nájdenie vašej rezervácie"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priezvisko"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID rezervácie"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum príchodu"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenašla sa žiadna rezervácia s týmito informáciami. Skontrolujte zadané údaje alebo kontaktujte hotel."])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začnite odchod"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončiť odchod"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odchod"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť online"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť na recepcii"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďakujem!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máte nevyužitý zostatok vkladu. Prosím, navštívte recepciu, aby ste dostali zostatok vkladu späť."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte dokument"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte, ktorý dokument chcete skenovať"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Občiansky preukaz"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preukaz trvalého pobytu"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predná strana občianskeho preukazu"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadná strana občianskeho preukazu"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpoznanie dokumentu a zhoda tváre boli úspešné. Prosím, skontrolujte presnosť."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrávajú sa vaše dokumenty..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovanie vašich dokumentov..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarovnajte zadnú stranu svojho občianskeho preukazu do rámu<br>a urobte fotku"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teraz naskenujte prednú stranu svojho občianskeho preukazu"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarovnajte svoj pas do rámu<br>a urobte fotku"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umiestnite vašu tvár do rámu"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontrolujte svoju fotku"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontrolujte svoju fotku. Uistite sa, že písmená sú<br>jednoznačné a je dostatočné osvetlenie"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overuje sa vaša fotka..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvár sa nezhoduje"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera nenájdená"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znova fotiť"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotiť"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všetko je hotové!"])}
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milý host"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dospelý"]), _normalize([_interpolate(_named("n")), " dospelí"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " dieťa"]), _normalize([_interpolate(_named("n")), " detí"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vyberte ", _interpolate(_named("item"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájsť moju rezerváciu"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áno"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niečo sa pokazilo.<br>Skúste to prosím znova."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatvoriť"])}
  }
}