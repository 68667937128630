const state = {
  order: null,
};

const mutations = {
  SET_ORDER(state, payload) {
    state.order = payload;
  },
};

const actions = {
  async setOrder({ commit }, payload) {
    commit("SET_ORDER", payload);
  },
};

export default {
  state,
  mutations,
  actions,
};
