export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γλώσσα"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το πεδίο είναι υποχρεωτικό"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ελάχιστος αριθμός χαρακτήρων – ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Μέγιστος αριθμός χαρακτήρων – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισάγετε έγκυρο email"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισάγετε έγκυρο κινητό τηλέφωνο"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισάγετε έγκυρη ημερομηνία"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ξεκινήστε την εγγραφή"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συγκαταθέσεις"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπογραφή σας"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκκαθάριση"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ αφήστε την υπογραφή σας"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διάβασα, κατανοώ και συμφωνώ με τους Όρους Υπηρεσίας"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ναι, συμφωνώ"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όχι, δεν συμφωνώ"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η Κράτησή σας"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ξενοδοχείο"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άφιξη"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναχώρηση"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διανυκτερεύσεις"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επισκέπτες"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πατώντας το κουμπί, συμφωνείτε <br /> με τους "])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όρους Υπηρεσίας"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνέχεια"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε λεπτομέρειες"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες Επισκέπτη"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνέχεια"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανεβασμένα έγγραφα"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λεπτομέρειες Επισκέπτη"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σαρώσεις Εγγράφων"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανεβάστε φωτογραφίες του διαβατηρίου σας ή σαρώστε το διαβατήριό σας για να επαληθεύσετε την ταυτότητά σας. Δεν αποθηκεύουμε αυτά τα δεδομένα."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάντε κλικ για να εισαγάγετε λεπτομέρειες για τον κύριο επισκέπτη"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάντε κλικ για να εισαγάγετε λεπτομέρειες για τον συνοδευτικό επισκέπτη"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε μια χώρα"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ, προετοιμάστε την ταυτότητά σας και το χαμογελαστό πρόσωπό σας για να προχωρήσετε στη διαδικασία αυτόματης ελέγχου και επαλήθευσης εγγράφων. Δεν θα διαρκέσει περισσότερο από 1 λεπτό."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανεβάστε το ταξιδιωτικό έγγραφο"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σαρώστε το ταξιδιωτικό έγγραφο"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ξεκινήστε τη διαδικασία επαλήθευσης"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδικά Αιτήματα"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αίτημα"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληρωμή"])},
      "arrivalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ώρα άφιξης"])},
      "earlierCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι πρώιμες αφίξεις υπόκεινται σε επιβεβαίωση από το κατάλυμα"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληρώστε online τώρα"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληρώστε κατά την άφιξη"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ επιλέξτε πώς θα θέλατε να πληρώσετε για τη διαμονή σας"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημείωση: Το προσωπικό του ξενοδοχείου θα επιβεβαιώσει την πιθανότητα πρόσθετων υπηρεσιών κατόπιν αιτήματος αργότερα. Δεν πληρώνετε για αυτές τη στιγμή αυτή."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η Διαμονή σας"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνέχεια"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαμονή"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιπλέον Υπηρεσίες"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληρωμένο"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνολο"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " νύχτα"]), _normalize([_interpolate(_named("n")), " νύχτες"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βελτιώστε τη Διαμονή σας"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε επιπλέον υπηρεσίες για να κάνετε τη διαμονή σας πιο ευχάριστη πριν φτάσετε και θα εκπλαγείτε ευχάριστα\n"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιδιωτική Μεταφορά"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ παρέχετε το αεροδρόμιο, την ημερομηνία άφιξης και τον αριθμό της πτήσης σας για να μπορέσουμε να οργανώσουμε τη μεταφορά για εσάς"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αεροδρόμιο"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός επισκεπτών"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία άφιξης"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός πτήσης"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευχαριστούμε!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγαπητέ"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η προεγγραφή σας ολοκληρώθηκε επιτυχώς. Πριν την άφιξη θα λάβετε από εμάς ένα τελικό email επιβεβαίωσης εγγραφής και οδηγίες. Ευχαριστούμε και ανυπομονούμε να σας καλωσορίσουμε στο Hoteza Demo Hotel."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαφές Ξενοδοχείου"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κάντε κλικ για αντιγραφή!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι συντεταγμένες έχουν αντιγραφεί!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποτυχία πληρωμής"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι πληροφορίες πληρωμής σας είναι λανθασμένες. Παρακαλώ, προσπαθήστε ξανά ή επιστρέψτε και επιλέξτε πληρωμή κατά την άφιξη."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δοκιμάστε ξανά"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ συμπληρώστε τα στοιχεία σας για να βρείτε την κράτησή σας"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επώνυμο"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός Κράτησης"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία Άφιξης"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε καμία κράτηση με αυτές τις πληροφορίες. Ελέγξτε τα δεδομένα που εισαγάγατε ή επικοινωνήστε με το ξενοδοχείο."])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ξεκινήστε την αναχώρηση"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολοκληρώστε την αναχώρηση"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναχώρηση"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληρώστε Online"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληρώστε στη Ρεσεψιόν"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευχαριστούμε!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ωχ!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε υπόλοιπο καταθέσεων που δεν χρησιμοποιήθηκε. Παρακαλώ επισκεφθείτε τη ρεσεψιόν για να λάβετε την επιστροφή του υπόλοιπου ποσού."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε έγγραφο"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ποιο έγγραφο θέλετε να σαρώσετε"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαβατήριο"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ταυτότητα"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάρτα Κατοίκου"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπροστινή πλευρά Ταυτότητας"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίσω πλευρά Ταυτότητας"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η αναγνώριση εγγράφου και η αντιστοίχιση προσώπου ήταν επιτυχής. Παρακαλώ, ελέγξτε για ακρίβεια."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανεβάζουμε τα έγγραφά σας..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικυρώνουμε τα έγγραφά σας..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευθυγραμμίστε την πίσω πλευρά της ταυτότητάς σας με το πλαίσιο<br>και τραβήξτε μια φωτογραφία"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τώρα σαρώστε την μπροστινή πλευρά της ταυτότητάς σας"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευθυγραμμίστε το διαβατήριό σας με το πλαίσιο<br>και τραβήξτε μια φωτογραφία"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τοποθετήστε το πρόσωπό σας στο πλαίσιο"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε τη φωτογραφία σας"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε τη φωτογραφία σας. Βεβαιωθείτε ότι τα γράμματα είναι<br>καθαρά και έχει καλή φωτισμένη"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικυρώνουμε τη φωτογραφία σας..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η αντιστοίχιση προσώπου απέτυχε"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε κάμερα"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ξανατραβήξτε"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα είναι έτοιμα!"])}
    },
    "feedback": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανατροφοδότηση"])},
      "mostLikeForm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τι σας άρεσε περισσότερο κατά τη διάρκεια της διαμονής σας;"])},
        "services": {
          "bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άνεση κρεβατιού"])},
          "movies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δωρεάν ταινίες"])},
          "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποιότητα φαγητού"])},
          "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μobile key"])},
          "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσωπικό ξενοδοχείου"])}
        }
      },
      "wouldRecommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θα συνιστούσατε το ξενοδοχείο μας σε άλλους;"])},
      "whatImpression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποια ήταν η γενική εντύπωση της διαμονής σας;"])},
      "foodQuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποιότητα φαγητού"])},
      "staffFriendliness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φιλικότητα προσωπικού ξενοδοχείου"])},
      "describeExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιγράψτε την εμπειρία σας από τη διαμονή σας στο ξενοδοχείο μας με τα δικά σας λόγια"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το σχόλιό σας"])},
      "ratings": {
        "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξαιρετικό"])},
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καλό"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέτριο"])},
        "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κακό"])}
      }
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγαπητέ Επισκέπτη"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνέχεια"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ενήλικας"]), _normalize([_interpolate(_named("n")), " ενήλικες"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " παιδί"]), _normalize([_interpolate(_named("n")), " παιδιά"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Επιλέξτε ένα ", _interpolate(_named("item"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βρείτε την κράτησή μου"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ναι"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όχι"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάτι πήγε στραβά.<br>Παρακαλώ προσπαθήστε ξανά."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλείσιμο"])}
  }
}