const state = {
  folio: {},
  expectedCheckoutTime: null,
  billEmail: null,
};

const mutations = {
  SET_FOLIO(state, payload) {
    state.folio = payload;
  },
  SET_EXPECTED_CHECKOUT_TIME(state, payload) {
    state.expectedCheckoutTime = payload;
  },
  SET_BILL_EMAIL(state, payload) {
    state.billEmail = payload;
  },
};

const actions = {
  setFolio({ commit }, payload) {
    commit("SET_FOLIO", payload);
  },
  setExpectedCheckoutTime({ commit }, payload) {
    commit("SET_EXPECTED_CHECKOUT_TIME", payload);
  },
  setBillEmail({ commit }, payload) {
    commit("SET_BILL_EMAIL", payload);
  },
};

export default {
  state,
  mutations,
  actions,
};
