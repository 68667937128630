const state = {
  currentLang: null,
  showLangSwitcher: false,
};
const mutations = {
  SET_LANG(state, payload) {
    state.currentLang = payload;
  },
  TOGGLE_LANG_SWITCHER(state, payload) {
    state.showLangSwitcher = payload;
  },
};
const getters = {
  currentLang(state) {
    return state.currentLang;
  },
  showLangSwitcher(state) {
    return state.showLangSwitcher;
  },
};

export default {
  state,
  mutations,
  getters,
};
