import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { maska } from "maska";

import axios from "@/axios";
import i18n from "./i18n";

const app = createApp(App).use(i18n);
app.directive("maska", maska);
app.config.globalProperties.$axios = axios;
app.use(store).use(router).mount("#app");
