export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo é obrigatório"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Número mínimo de caracteres – ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Número máximo de caracteres – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite um e-mail válido"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite um telefone móvel válido"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite uma data válida"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar check-in"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentimentos"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua Assinatura"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, deixe sua assinatura"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Li, entendi e concordo com os Termos de Serviço"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim, eu concordo"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não, eu discordo"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua Reserva"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noites"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hóspedes"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao pressionar o botão, você concorda <br /> com os "])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termos de Serviço"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver detalhes"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do Hóspede"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos enviados"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes do Hóspede"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escaneamentos de Documentos"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça o upload das fotos do seu passaporte ou escaneie seu passaporte para verificar sua identidade. Não armazenamos esses dados."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique para inserir detalhes do hóspede principal"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique para inserir detalhes do hóspede acompanhante"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um país"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, prepare seu documento de identidade e seu rosto sorridente para o processo automático de verificação de documentos. Não levará mais de 1 minuto."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar documento de viagem"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanear documento de viagem"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar processo de verificação"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos Especiais"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
      "arrivalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora de chegada"])},
      "earlierCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chegadas antecipadas estão sujeitas a confirmação por parte da propriedade"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar online agora"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar na chegada"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, escolha como deseja pagar pela sua estadia"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso: A equipe do hotel confirmará a possibilidade de serviços adicionais mediante solicitação posteriormente. Você não pagará por eles neste momento."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua Estadia"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acomodação"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviços Extras"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " noite"]), _normalize([_interpolate(_named("n")), " noites"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melhore Sua Estadia"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione extras para iluminar sua estadia antes de chegar e você ficará agradavelmente surpreso\n"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência Privada"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, forneça seu aeroporto, data de chegada e número do voo para que possamos providenciar um transfer para você"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aeroporto"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de hóspedes"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de chegada"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do voo"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrigado!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caro(a)"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu pré-check-in foi concluído com sucesso. Antes da chegada, você receberá um e-mail de confirmação final do check-in e instruções. Obrigado e esperamos recebê-lo no Hoteza Demo Hotel."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatos do Hotel"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clique para copiar!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As coordenadas foram copiadas!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento falhou"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As informações de pagamento estão incorretas. Por favor, tente novamente ou volte e selecione pagamento na chegada."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentar novamente"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, preencha seus dados para encontrar sua reserva"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID da Reserva"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de chegada"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma reserva com essas informações foi encontrada. Verifique os dados que você inseriu ou entre em contato com o hotel."])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar check-out"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluir check-out"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar Online"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar na Recepção"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrigado!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você tem um saldo de depósito não utilizado. Por favor, visite a recepção para receber o reembolso do saldo restante."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar documento"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione qual documento você deseja escanear"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporte"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carteira de Identidade"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartão de Residência"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Frontal"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Traseiro"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconhecimento do documento e correspondência facial bem-sucedidos. Por favor, revise para verificar a precisão."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviando seus documentos..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validando seus documentos..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alinhe o verso do seu ID com a moldura<br>e tire uma foto"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agora escaneie o lado frontal do seu ID"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alinhe seu passaporte com a moldura<br>e tire uma foto"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coloque seu rosto na moldura"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revise sua foto"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revise sua foto. Certifique-se de que as letras estão<br>claras e que a iluminação está boa"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validando sua foto..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O rosto não corresponde"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câmera não encontrada"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capturar"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo pronto!"])}
    },
    "feedback": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
      "mostLikeForm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O que você mais gostou durante a sua estadia?"])},
        "services": {
          "bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conforto da cama"])},
          "movies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filmes gratuitos"])},
          "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualidade da comida"])},
          "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chave móvel"])},
          "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipe do hotel"])}
        }
      },
      "wouldRecommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você recomendaria nosso hotel para outras pessoas?"])},
      "whatImpression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qual foi a impressão geral da sua estadia?"])},
      "foodQuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualidade da comida"])},
      "staffFriendliness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amabilidade da equipe do hotel"])},
      "describeExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descreva sua experiência de estadia em nosso hotel com suas próprias palavras"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu comentário"])},
      "ratings": {
        "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excelente"])},
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bom"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médio"])},
        "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruim"])}
      }
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caro Hóspede"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " adulto"]), _normalize([_interpolate(_named("n")), " adultos"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " criança"]), _normalize([_interpolate(_named("n")), " crianças"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Escolha um(a) ", _interpolate(_named("item"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar minha reserva"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo deu errado.<br>Por favor, tente novamente."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])}
  }
}