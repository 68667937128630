const state = [];

import dayjs from "dayjs";

const mutations = {
  SET_DATA(state, { payload, index }) {
    state[index] = {};
    state[index].firstName = payload.firstName || null;
    state[index].lastName = payload.lastName || null;
    state[index].middleName = payload.middleName || null;

    state[index].dateOfBirth = payload.dateOfBirth
      ? dayjs(payload.dateOfBirth, "YYYY-MM-DD").format("DD.MM.YYYY")
      : null;

    state[index].placeOfBirth = payload.placeOfBirth || null;

    state[index].gender = payload.gender || null;
    state[index].address = payload.address || null;
    state[index].documentNumber = payload.documentNumber || null;
    state[index].documentSeries = payload.documentSeries || null;

    state[index].documentIssueDate = payload.documentIssueDate
      ? dayjs(payload.documentIssueDate, "YYYY-MM-DD").format("DD.MM.YYYY")
      : null;

    state[index].documentExpiryDate = payload.documentExpiryDate
      ? dayjs(payload.documentExpiryDate, "YYYY-MM-DD").format("DD.MM.YYYY")
      : null;
    state[index].country = payload.country || null;
    state[index].nationality = payload.nationality || null;
    state[index].documentAuthority = payload.documentAuthority || null;
    state[index].documentSubDivisionCode = payload.documentSubdivision || null;
    state[index].documentType = payload.documentType || null;
  },
  CLEAR_DATA(state, index) {
    state[index] = undefined;
  },
};

const actions = {
  async setData({ commit }, { payload, index }) {
    commit("SET_DATA", { payload, index });
  },
  async clearData({ commit }, index) {
    commit("CLEAR_DATA", index);
  },
};

export default {
  state,
  mutations,
  actions,
};
