<template>
  <transition name="appAlertTransition" @after-leave="afterLeave">
    <div
      v-show="alertShow"
      ref="appAlert"
      class="appAlert"
      :class="`appAlert_${type}`"
    >
      <p class="appAlert__message">
        {{ alertMessage }}
      </p>
    </div>
  </transition>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "AppAlert",
  watch: {
    async show(val) {
      if (val) {
        setTimeout(() => {
          this.HIDE_ALERT();
        }, 7000);
      }
    },
  },
  methods: {
    ...mapMutations({
      HIDE_ALERT: "alert/HIDE_ALERT",
      CLEAR_MESSAGE: "alert/CLEAR_MESSAGE",
    }),
    afterLeave() {
      this.CLEAR_MESSAGE();
    },
  },

  computed: {
    ...mapGetters({
      message: "alert/message",
      show: "alert/show",
      type: "alert/type",
    }),
    alertMessage() {
      return this.message;
    },
    alertShow() {
      return this.show;
    },
  },
};
</script>

<style lang="scss">
.appAlertTransition-enter-active,
.appAlertTransition-leave-active {
  transition: transform 0.3s ease-out;
}

.appAlertTransition-enter-from,
.appAlertTransition-leave-to {
  transform: translateY(-100%);
}

.appAlertTransition-enter-to,
.appAlertTransition-leave-from {
  transform: translateY(0);
}

.appAlert {
  padding: 15px 20px;
  position: fixed;
  z-index: 100;
  max-width: 600px;
  width: 100%;
  top: 0;
  @include media-breakpoint-up(sm) {
    left: calc((100vw - 600px) / 2);
  }
  &_danger {
    background: #ca3434;
  }

  &_success {
    background: #439b47;
  }
}

.appAlert__message {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--color-white);
  @include media-breakpoint-up(sm) {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
