import { createStore } from "vuex";
import alert from "@/store/alert";
import language from "@/store/language";
import booking from "@/store/booking";
import ocr from "@/store/ocr";
import payments from "@/store/payments";
import checkout from "@/store/checkout";

export default createStore({
  modules: {
    core: {},
    language,
    alert: {
      namespaced: true,
      ...alert,
    },
    booking: {
      namespaced: true,
      ...booking,
    },
    ocr: {
      namespaced: true,
      ...ocr,
    },
    payments: {
      namespaced: true,
      ...payments,
    },
    checkout: {
      namespaced: true,
      ...checkout,
    },
  },
});
