<template>
  <AppAlert />
  <div id="wrapper" class="wrapper">
    <router-view />
  </div>
</template>
<style lang="scss">
@import "./assets/fonts/fonts.css";
@import "./assets/default.css";

* {
  font-family: var(--font-family-main);
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizelegibility;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  background-color: var(--color-bg) !important;
  min-height: -webkit-fill-available;
  width: 100vw;
}

#app {
  background-color: var(--color-bg);
  max-width: 100vw;
  width: 100vw;
}

.wrapper {
  //min-height: 100vh;
  margin: 0 auto;
  width: 100%;
  position: relative;
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
  height: auto;
  @include media-breakpoint-up(sm) {
    max-width: 640px;
  }
}

.container {
  @include media-breakpoint-up(sm) {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }
}
</style>

<script>
import AppAlert from "@/components/UI/AppAlert";

export default {
  components: { AppAlert },
  created() {
    document.title = "Check-in by Hoteza";
  },
  mounted() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
};
</script>
