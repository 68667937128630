const state = {
  message: null,
  show: false,
  type: null,
};
const mutations = {
  SHOW_ALERT(state, payload) {
    if (typeof payload === "string") {
      state.message = payload;
      state.type = "danger";
    }
    if (typeof payload === "object") {
      const { message, type } = payload;
      state.message = message;
      ["danger", "success"].includes(type)
        ? (state.type = type)
        : (state.type = "danger");
    }
    state.show = true;
  },
  HIDE_ALERT(state) {
    state.show = false;
  },
  CLEAR_MESSAGE(state) {
    state.message = null;
  },
};
const getters = {
  message(state) {
    return state.message;
  },
  show(state) {
    return state.show;
  },
  type(state) {
    return state.type;
  },
};

export default {
  state,
  mutations,
  getters,
};
